$(document).ready(function(){
	
	!function(e){"function"==typeof define&&define.amd?define(["jquery"],e):e(jQuery)}(function(e){function n(e){return e}function o(e){return decodeURIComponent(e.replace(t," "))}function i(e){0===e.indexOf('"')&&(e=e.slice(1,-1).replace(/\\"/g,'"').replace(/\\\\/g,"\\"));try{return r.json?JSON.parse(e):e}catch(n){}}var t=/\+/g,r=e.cookie=function(t,c,a){if(void 0!==c){if(a=e.extend({},r.defaults,a),"number"==typeof a.expires){var u=a.expires,f=a.expires=new Date;f.setDate(f.getDate()+u)}return c=r.json?JSON.stringify(c):String(c),document.cookie=[r.raw?t:encodeURIComponent(t),"=",r.raw?c:encodeURIComponent(c),a.expires?"; expires="+a.expires.toUTCString():"",a.path?"; path="+a.path:"",a.domain?"; domain="+a.domain:"",a.secure?"; secure":""].join("")}for(var d=r.raw?n:o,p=document.cookie.split("; "),s=t?void 0:{},m=0,x=p.length;x>m;m++){var l=p[m].split("="),g=d(l.shift()),v=d(l.join("="));if(t&&t===g){s=i(v);break}t||(s[g]=i(v))}return s};r.defaults={},e.removeCookie=function(n,o){return void 0!==e.cookie(n)?(e.cookie(n,"",e.extend(o,{expires:-1})),!0):!1}});
	
	var rgpd_manager = $('#rgpd_manager');
	
	if($.cookie('LeyCookiesCobega') == 'all'){
		accept();
	}else if($.cookie('LeyCookiesCobega') == 'none'){
		reject();
	}else{
		$('body').addClass('show_rgpd_manager');
	}
	
	$('#rgpd_manager .rgpdm_close').on('click', function(e){
		e.preventDefault();
		
		if(rgpd_manager.hasClass('show_politica')){
			rgpd_manager.removeClass('show_politica');
		}else if(rgpd_manager.hasClass('show_config')){
			rgpd_manager.removeClass('show_config');
		}
		
		$('#rgpd_manager .rgpdm_close').hide();
	});
	
	$('#rgpd_manager .rgpdm_back').on('click', function(e){
		e.preventDefault();
		rgpd_manager.removeClass();
		$('#rgpd_manager .rgpdm_close').hide();
	});
	
	$('#rgpd_manager .rgpdm_show_politica').on('click', function(e){
		e.preventDefault();
		
		rgpd_manager.removeClass().addClass('show_politica');
		$('#rgpd_manager .rgpdm_close').show();
	});
	
	$('#rgpd_manager .rgpdm_show_config').on('click', function(e){
		e.preventDefault();
		
		rgpd_manager.removeClass().addClass('show_config');
		$('#rgpd_manager .rgpdm_close').show();
	});
	
	$('#rgpd_manager .rgpdm_reject').on('click', function(e){
		e.preventDefault();
		
		reject();
		$.cookie('LeyCookiesCobega', 'none', { expires: 356, path: '/' });
	});
	
	$('#rgpd_manager .rgpdm_accept').on('click', function(e){
		e.preventDefault();
		
		accept();
		$.cookie('LeyCookiesCobega', 'all', { expires: 356, path: '/' });
	});
	
	$('#rgpd_manager .rgpdm_save').on('click', function(e){
		e.preventDefault();
		
		
		if($('#rgpdm-cookie-ga').is(":checked")){
			accept();
			$.cookie('LeyCookiesCobega', 'all', { expires: 356, path: '/' });
		}else{
			reject();
			$.cookie('LeyCookiesCobega', 'none', { expires: 356, path: '/' });
		}
	});
	
	$('#rgpdm-cookie-all').on('click', function(e){
		if($(this).is(":checked")){
			$('.rgpdm_item .rgpdm_toggle-field:not(.disabled) input').prop('checked', true);
		}else{
			$('.rgpdm_item .rgpdm_toggle-field:not(.disabled) input').prop('checked', false);
		}
	});

	
	function accept(){
		window['ga-disable-' + window['gacode']] = false;
		ga('send', 'pageview');
		rgpd_manager.remove();
		$('body').removeClass('show_rgpd_manager');
	}
	
	function reject(){
		window['ga-disable-' + window['gacode']] = true;
		rgpd_manager.remove();
		$('body').removeClass('show_rgpd_manager');
	}
	
});